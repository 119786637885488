import React from 'react';
import { useAuth } from './useAuth';
import './Footer.css';
import { Link, useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
const { Footer } = Layout;



const AppFooter = () => {
  
  const { doUserLogOut } = useAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogout = async () => {
    await doUserLogOut(); // Assuming doUserLogOut is an async function
    navigate('/login'); // Redirect to sign-in page after logout
  };
  
  return (
    <Footer className="footer">
       <p>Surf Journal 2025</p>
       <p><a href="/notes">Notes</a><a href="/notes/privacy-policy">Privacy Policy</a> <a href="/notes/terms-of-service">Terms of Service</a></p>

       
    </Footer>
  );
}

export default AppFooter;